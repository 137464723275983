<template>
  <div class="text-template-list">
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex lg12>
          <Toolbar :handle-add="addHandler" :title="$t('Pages')" />
          <DataFilter :handle-filter="onSendFilter" :handle-reset="resetFilter">
            <PageFilterForm :values="filters" ref="filterForm" slot="filter" />
          </DataFilter>
          <br />
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template slot="item.slug" slot-scope="{ item }">
              <a :href="`https://${activeTenant.domain}/${item.slug}`" title="Slug" target="_blank">/{{ item.slug }}</a>
            </template>
            <template slot="item.enabled" slot-scope="{ item }">
              <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-close-circle</v-icon>
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import ListMixin from "../../mixins/ListMixin";
import ActionCell from "../../components/ActionCell";
import DataFilter from "../../components/DataFilter";
import Toolbar from "../../components/Toolbar";
import PageFilterForm from "../../components/page/Filter";
import TenantMixin from "../../mixins/TenantMixin";

export default {
  name: "PageList",
  servicePrefix: "Page",
  mixins: [ListMixin, TenantMixin],
  components: {
    Toolbar,
    ActionCell,
    DataFilter,
    PageFilterForm,
  },
  data() {
    return {
      headers: [
        { text: this.$i18n.t("title"), value: "title" },
        { text: this.$i18n.t("metaTitle"), value: "metaTitle" },
        { text: this.$i18n.t("slug"), value: "slug" },
        { text: this.$i18n.t("enabled"), value: "enabled" },
        {
          text: this.$i18n.t("Actions"),
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      selected: [],
      filters: {
        page: {},
        __Fexists: { parent: false },
      },
      showPageDialog: false,
      pageItem: {},
      showPageDialogForm: false,
      pageFormItem: {},
      pageDialogTitle: this.$t("AddPage"),

    };
  },
  computed: {
    ...mapGetters("page", {
      items: "list",
    }),
    ...mapFields("page", {
      deletedItem: "deleted",
      error: "error",
      isLoading: "isLoading",
      resetList: "resetList",
      totalItems: "totalItems",
      view: "view",
    }),
  },
  methods: {
    ...mapActions("page", {
      getPage: "fetchAll",
      deleteItem: "del",
    }),
    resetFilter() {
      this.filters = {
        profile: {},
      };
    },
    pageDialogControl(close = false) {
      this.showPageDialogForm = !close;
    },
    handleCreated(item) {
      console.log(item);
    },
    handleUpdated(item) {
      console.log(item);
    },
  },
};
</script>
