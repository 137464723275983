<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6">
        <v-text-field
          :label="$t('title')"
          type="text"
          v-model="item.title"
          clearable
        />
      </v-col>
      <v-col cols="6">
        <v-text-field
          :label="$t('metaTitle')"
          type="text"
          v-model="item.metaTitle"
          clearable
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PageFilter",
  props: {
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  mounted() {},

  computed: {
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
  },
  methods: {},
};
</script>
